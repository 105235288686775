import React from 'react';

import aboutUsPeople from 'UI/assets/images/aboutUsPeople.webp';
import { Banner } from 'UI/components/pages/Home/Banner/Banner';
import { ThreeItemsBlock } from 'UI/common/components/ThreeItemsBlock/ThreeItemsBlock';
import { OurPartners } from 'UI/common/components/OurPartners/OurPartners';
import { useGetTranslatedData } from 'utils/contentData';
import { TwoSectionBlock } from 'UI/common/components/TwoSectionBlock/TwoSectionBlock';
import { Faq } from 'UI/common/components/Faq/Faq';
import { StyledExpandedContainer } from 'UI/components/MainLayout/styled';
import { homeAndAboutFaqData } from 'constants/FaqData';
import { threeItemsAboutUsData } from 'constants/ThreeItemsBlockData';
import { HelmetWrapper } from 'components/HelmetWrapper/HelmetWrapper';
import {
  StyledContainer, StyledFaqContainer, PeopleBlock,
} from './styled';

export const AboutUs = () => {
  const data = useGetTranslatedData();

  return (
    <>
      <HelmetWrapper />
      <StyledExpandedContainer>
        <StyledContainer>
          <PeopleBlock>
            <img src={aboutUsPeople} alt="" />
          </PeopleBlock>
          <Banner data={data.aboutUs.banner} about />
          <ThreeItemsBlock dataArray={threeItemsAboutUsData} />
          <TwoSectionBlock data={data.aboutUs} />
          <TwoSectionBlock data={data.aboutUs} pictureRight />
        </StyledContainer>
      </StyledExpandedContainer>
      <StyledFaqContainer>
        <Faq faqArr={homeAndAboutFaqData} title="commonFrequentlyQuestions" />
        <OurPartners />
      </StyledFaqContainer>
      <StyledExpandedContainer />
    </>
  );
};
