import React from 'react';
import { useTranslation } from 'react-i18next';

import { BlockTitle } from 'UI/common/components/Titles/BlockTitle';
import partnerImage2 from 'UI/assets/images/OPR_foretags.webp';
import partnerImage3 from 'UI/assets/images/ABS_Factoring.webp';
import partnerImage4 from 'UI/assets/images/CAPBOX-blue.webp';
import partnerImage5 from 'UI/assets/images/Qred-logo.webp';
import partnerImage6 from 'UI/assets/images/Fedelta-logo.webp';
import partnerImage7 from 'UI/assets/images/Froda-logo.webp';
import { Flex } from 'UI/common/components/Flex';
import { StyledContainer, PartnerWrapper, StyledImage } from './styled';

export const OurPartners = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <Flex direction="column" align="center">
        <BlockTitle size="28px" weight={700} marginBottom="46px">
          {t('ourPartners')}
        </BlockTitle>
        <PartnerWrapper>
          <StyledImage src={partnerImage2} alt="foretagsl" />
          <StyledImage src={partnerImage3} alt="ABS_Factoring" />
          <StyledImage src={partnerImage4} alt="CAPBOX" />
          <StyledImage src={partnerImage5} alt="Qred-logo" />
          <StyledImage src={partnerImage6} alt="Fedelta-logo" />
          <StyledImage src={partnerImage7} alt="Froda-logo" />
        </PartnerWrapper>
      </Flex>
    </StyledContainer>
  );
};
