import React from 'react';

import ellipseBackground from 'UI/assets/images/ellipseContent.webp';
import { EllipseBlock, StyledContainer } from './styled';

export const RightSide = (props) => {
  const { data } = props;
  return (
    <StyledContainer {...props}>
      <img src={data.image} alt="" />
      <EllipseBlock {...props}>
        <img src={ellipseBackground} alt="" />
      </EllipseBlock>
    </StyledContainer>
  );
};
