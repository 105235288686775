import { useTranslation } from 'react-i18next';
import image1 from 'UI/assets/images/image-1.webp';
import image2 from 'UI/assets/images/image-2.webp';
import twoSectionBlockImg1 from 'UI/assets/images/twoSectioBlockImg1_aboutUs.webp';
import twoSectionBlockImg2 from 'UI/assets/images/twoSectionBlockImg2.webp';
import message from 'UI/assets/images/icons/Message.svg';
import profile from 'UI/assets/images/icons/Profile.svg';
import work from 'UI/assets/images/icons/work.svg';
import sweden from 'UI/assets/images/icons/sweden.svg';
import uk from 'UI/assets/images/icons/uk.svg';
import factoringImgBanner from 'UI/assets/images/factoringImgBanner.webp';
import bannerSectionImage from 'UI/assets/images/bannerSectionImage.webp';
import calling from 'UI/assets/images/icons/calling.svg';
import twoSectionLoanImg from 'UI/assets/images/twoSectionLoanImg.webp';

export const useGetTranslatedData = () => {
  const { t } = useTranslation();

  return (
    {
      language: {
        sv: {
          icon: sweden, text: 'SVE',
        },
        en: {
          icon: uk, text: 'ENG',
        },
      },
      home: {
        twoSectionBlock: {
          rightSideImage: {
            title: t('avoidManualWork'),
            text: t('integrationsAccountingSystems'),
            image: image1,
            button: { showButton: false, text: t('readMoreBtn') },
          },
          leftSideImage: {
            title: t('financedSweden'),
            text: t('integrationsAccountingSystems2'),
            image: image2,
            button: { showButton: true, text: t('readMoreBtn') },
          },
        },
        banner: {
          title: t('offersOnBusiness'),
          span: t('getMultipleOffers'),
          button: { showButton: true, text: t('startHereBtn') },
        },
        contentBanner: {
          title: t('investInBusiness'),
          span: t('multipleOffers'),
          button: t('startHereBtn'),
          image: bannerSectionImage,
        },
      },
      aboutUs: {
        banner: {
          title: t('businessNeedsHelp'),
          span: t('flexibleFinancing'),
          button: { showButton: false },
        },
        twoSectionBlock: {
          rightSideImage: {
            title: t('continueGrow'),
            text: t('highestQualityAccessibility'),
            image: twoSectionBlockImg2,
            button: { showButton: false },
          },
          leftSideImage: {
            title: t('rightPartner'),
            text: t('sameDesire'),
            image: twoSectionBlockImg1,
            button: { showButton: false },
          },

        },
      },
      contactUs: {
        banner: {
          title: t('howCanHelp'),
          span: t('useContactForm'),
          button: { showButton: false },
          showMobileBlock: true,
        },
        form: [
          {
            name: 'userName',
            placeholder: t('yourName'),
            icon: profile,
            type: 'text',
          },
          {
            name: 'email',
            placeholder: t('emailAddress'),
            icon: message,
            type: 'email',
          },
          {
            name: 'phoneNumber',
            placeholder: t('phoneNumber'),
            icon: calling,
            type: 'text',
          },
        ],
      },
      factoring: {
        twoSectionBlock: {
          leftSideImage: {
            title: t('reduceRisk'),
            text: t('clearIdeaFinanced'),
            image: factoringImgBanner,
            button: { showButton: false },
          },
        },
        banner: {
          title: t('multipleOffersFact'),
          span: t('termsOfYourBusiness'),
          button: { showButton: false },
          items: {
            item1: t('proposalWithIn'),
            item2: t('getUpInstantly'),
            item3: t('freeService'),
          },
        },
        form: [
          {
            name: 'companyRegistrationNumber',
            placeholder: t('whatOrganizationNumber'),
            icon: work,
            type: 'text',
          },
          {
            name: 'phoneNumber',
            placeholder: t('whatPhoneNumber'),
            icon: calling,
            type: 'tel',
          },
          {
            name: 'email',
            placeholder: t('whatEmail'),
            icon: message,
            type: 'email',
          },
          {
            name: 'productsDescription',
            placeholder: t('expectedToSell'),
            type: 'text',
          },
          {
            name: 'invoicesNum',
            placeholder: t('howManyInvoices'),
            type: 'text',
          },
          {
            name: 'averageInvoice',
            placeholder: t('averageInvoice'),
            type: 'text',
          },
        ],
      },
      loan: {
        twoSectionBlock: {
          rightSideImage: {
            title: t('flexibilityAndPrice'),
            text: t('foundedFinanced'),
            image: twoSectionLoanImg,
            button: { showButton: false },
          },
        },
        banner: {
          title: t('multipleOffersBusinessLoans'),
          span: t('betterTermsBusiness'),
          button: { showButton: false },
          items: {
            item1: t('specialisedHassleFree'),
            item2: t('getUpInstantly'),
            item3: t('offersWithInOneDay'),
          },
        },
        form: [
          {
            name: 'contactEmail',
            placeholder: t('emailAddress'),
            icon: message,
            type: 'email',
          },
          {
            name: 'contactPhoneNumber',
            placeholder: t('phoneNumber'),
            icon: calling,
            type: 'tel',
          },
        ],
      },
    }
  );
};
