import React from 'react';

import { StyledBorderContainer, StyledFlag } from './styled';

export const Flag = ({ data, onLangSelect }) => (
  <StyledBorderContainer onClick={() => onLangSelect(data.text.toLowerCase())}>
    <StyledFlag>
      <div className="FlagBlock">
        <img className="flag" src={data.icon} alt="" />
      </div>
      <span className="country">{data.text}</span>
    </StyledFlag>
  </StyledBorderContainer>
);
